import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import Gravatar from 'vue-gravatar'
import VueSwal from 'vue-swal'
import { sync } from 'vuex-router-sync'
import moment from 'moment'
import wb from './registerServiceWorker'
import 'leaflet/dist/leaflet.css'
import VueApexCharts from 'vue-apexcharts'


sync(store, router)

// per l'autoupdate della pwa
Vue.prototype.$workbox = wb

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('v-gravatar', Gravatar)
Vue.use(VueSwal)

Vue.config.productionTip = false

// ***********************************************
// *** FILTRI GLOBALI ****************************
// ***********************************************

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('standardDate', function (value) {
	moment.locale('it')
	return value ? moment(value).format('DD MMMM YYYY') : ''
})
Vue.filter('standardDateWithHour', function (value) {
	moment.locale('it')
	return value ? moment(value).format('DD MMMM YYYY - HH:mm:ss') : ''
})
Vue.filter('standardHour', function (value) {
	moment.locale('it')
	return value ? moment(value).format('HH:mm:ss') : ''
})
Vue.filter('shortDate', function (value) {
	moment.locale('it')
	return value ? moment(value).format('DD MMM YYYY') : ''
})
Vue.filter('reportShortDate', function (value) {
	moment.locale('it')
	return value && moment(value).isAfter('1900-1-1') ? moment(value).format('DD-MMM-YYYY') : ''
})
Vue.filter('monthName', function (value) {
	moment.locale('it')
	return value ? moment(value, 'M').format('MMMM') : ''
})
Vue.filter('fileSize', function (size) {
	if (isNaN(size)) return '-'
	if (size === 0) return '-'
	if (size < 1024) return size + ' Bytes'
	size /= 1024
	if (size < 1024) return size.toFixed(1) + ' Kb'
	size /= 1024
	if (size < 1024) return size.toFixed(1) + ' Mb'
	size /= 1024
	if (size < 1024) return size.toFixed(1) + ' Gb'
	size /= 1024
	return size.toFixed(2) + ' Tb'
})
Vue.filter('toCurrency', function (value) {
	if (typeof value !== 'number') {
		return value
	}
	var formatter = new Intl.NumberFormat('it-IT', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 0
	})
	return formatter.format(value)
})
Vue.filter('truncate', function (text, length) {
	return text.slice(0, length) + (length < text.length ? '...' : '')	
})

// ***********************************************
// *** METODI GLOBALI *************************
// ***********************************************

// determina l'autorizzazione di un utente ad accedere ad un dato MenuId. In pratica verifica che l'id indicato sia presente tra quelli assegnati all'utente (lista AuthorizedRoutes)
// nota: questo metodo è stato implementato qui e così per poter essere richiamato ovunque, sia da codice come this.$isAuthorizedTo('xxx') che nei template come  v-if="$isAuthorizedTo('xxx')" 
// inoltre esegue l'accesso diretto allo store (e non usa actions) per poter funzionare in modo completamente sincrono 
Vue.prototype.$isAuthorizedTo = function(menuId) {
	let isLoggedIn = store.state.isLoggedIn
	let authorizedRoutes = store.state.user.AuthorizedRoutes
	let isAuthorized = isLoggedIn && !!authorizedRoutes.find( routeName => { return routeName === menuId } ) 
	// console.warn('isAuthorizedTo(' + menuId + ')='+isAuthorized+' - isLoggedIn='+isLoggedIn + ' ')
	return isAuthorized
}


// ***********************************************
new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
