import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/router.js'
// import moment from 'moment'
// import createPersistedState from 'vuex-persistedstate'
// moduli specifici per le varie entity
// import UserModule from '@/store/user-module'

Vue.use(Vuex)

// ------------------------------------------------------------------------------------------------------
// --- STATE --------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const state = {
	showNetworkError: false,
	state: '',
	// versione UI (ricavata da package e autoincrementata tramite yarn version)
	uiName: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7B"name"%3A0%7D')).name,
	uiVersion: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7B"version"%3A0.0%7D')).version,
	uiBetaTag: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7B"beta"%3A0%7D')).beta,
	// versione delle api (ricavata da ApplicationInformation.ExecutingAssemblyVersion)
	apiVersion: '',
	// data di compilazioen delle api (serve un cazzo ma fa figo)
	apiDate: '',
	// versione ui richiesta dall'api (è impostata manualmente nel web.config) - serve per invalidare la cache ma attualmente non è implementata - todo: vedere le logiche usate per la ui del TK
	uiRequestedVersion: '',
	token: null,
	keepAliveIntervalId: null,
	usersStatus: [],
	loading: false,
	// struttura di setup globale
	setup: {},
	// keyvalue dell'utente corrente
	keyvalues: [],
	// utente corrente
	user: {},
	// struttura menu
	menu: [],
	// raggruppa tutte le sottotabelle di decodifica
	decodifiche: {},
	// gruppi utenti
	gruppiUtenti: [],
	// statistiche per dash
	stat: {},
	// ----------------
	// se true il login è ok
	isLoggedIn: false,
	// contatore fallimenti di keepalive
	keepAliveFail: 0,
	// rende visibili gli elementi di debug in test e develop mode
	viewDebugElements: false,
	// default visualizzazione campi edit
	tipologiaCampiEdit: {
		filled: true,
		solo: false,
		outlined: false,
		dense: true,
	},
	// visluazlizzazione compatta (dense sulle v-row in sostanza)
	compactVisualization: true,
	// modalita apertura numerdi di cellulare (defautl = false = dialer, true = whatsapp)
	cellularOpenMode: false,
	personeExitAfterSave: false,
	// ---------------- stati utility senza getter
	// contatore incrementale negativo per gli id temporanei
	negativeIncrementalId: 0
}


// ------------------------------------------------------------------------------------------------------
// --- GETTERES -----------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const getters = {
	showNetworkError: state => state.showNetworkError,
	// path delle immagini delle persone (tiene conto delle variazioni tra sviluppo e produzione)
	imagesPathPerson: () => process.env.VUE_APP_WEBAPIROOT + 'public/person/',
	uiVersion: state => state.uiVersion,
	uiVersionBetaTag: state => state.uiBetaTag,
	apiVersion: state => state.apiVersion,
	apiDate: state => state.apiDate,
	isLoggedIn: state => state.isLoggedIn,
	loading: state => state.loading,
	keyvalues: state => state.keyvalues,
	setup: state => state.setup,
	currentUser: state => state.user,
	decodifiche: state => state.decodifiche,
	appMenu: state => state.menu,
	gruppiUtenti: state => state.gruppiUtenti,
	viewDebugElements: state => state.viewDebugElements,
	tipologiaCampiEdit: state => state.tipologiaCampiEdit,
	compactVisualization: state => state.compactVisualization,
	cellularOpenMode: state => state.cellularOpenMode,
	personeExitAfterSave: state => state.personeExitAfterSave,
	usersStatus: state => state.usersStatus,
	
}

// ------------------------------------------------------------------------------------------------------
// --- MUTATIONS ----------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const mutations = {
	SET_TOKEN(state, token) {
		state.token = token
	},
	NETWORK_ERROR(state, show) {
		state.showNetworkError = show
	},
	SET_VERSION(state, data) {
		state.apiVersion = data.AppVersion
		state.apiDate = data.AppDate
		state.uiRequestedVersion = data.UiRequestedVersion
	},
	SET_UTENTE(state, user) {
		state.user = user
	},
	SET_MENU(state, menu) {
		state.menu = menu
	},
	SET_KEYVALUES(state, keyvalues) {
		state.keyvalues = keyvalues
	},
	SET_SETUP(state, setup) {
		state.setup = setup
	},
	// salva il blocco delle decodifiche 
	SET_DECODIFICHE(state, decodifiche) {
		state.decodifiche = decodifiche
	},
	// aggiunge una  decodifica
	MODIFY_DECODIFICA_ITEM(state, item) {
		if(item.op === 'add') {
			state.decodifiche[item.table].push(item.value)
		} else if(item.op === 'update') {
			state.decodifiche[item.table].splice(item.index, 1, item.value)
		} else {
			// op non prevista
		}
	},
	// salva il blocco gruppiUtenti
	SET_GRUPPIUTENTI(state, gruppiUtenti) {
		state.gruppiUtenti = gruppiUtenti
	},
	//
	// prelogin: attiva il flag di loading che poi verrà disattivato a login completato ( o fallito)
	INIT(state) {
		state.loading = true
	},
	INIT_FAILED(state) {
		state.loading = false
	},
	// attiva  login
	LOGIN(state, keepAliveIntervalId) {
		state.loading = false
		state.isLoggedIn = true
		state.keepAliveIntervalId = keepAliveIntervalId
	},
	// logout e cancellazione stati
	LOGOUT(state) {
		router.push({name: 'home'})
		clearInterval(state.keepAliveIntervalId)
		state.isLoggedIn = false
		state.token = null
		state.user = {}
		state.menu = []
	},
	KEEPALIVE_OK(state, usersStatus) {
		state.keepAliveFail = 0
		state.usersStatus = usersStatus
	},
	KEEPALIVE_FAIL(state) {
		state.keepAliveFail = state.keepAliveFail + 1
	},
	TOGGLE_DEBUG_ELEMENTS(state) {
		state.viewDebugElements = !state.viewDebugElements
	},
	INCREMENT_NEGATIVE_ID(state) {
		state.negativeIncrementalId--
	},
	SET_TIPOLOGIA_CAMPI_EDIT(state, value) {
		state.tipologiaCampiEdit = value
	},
	TOGGLE_COMPACT_VISUALIZATION(state) {
		state.compactVisualization = !state.compactVisualization
	},
	TOGGLE_CELLULAR_OPEN_MODE(state) {
		state.cellularOpenMode = !state.cellularOpenMode
	},
	TOGGLE_PERSONE_EXIT_AFTER_SAVE(state) {
		state.personeExitAfterSave = !state.personeExitAfterSave
	},
}


// ------------------------------------------------------------------------------------------------------
// --- ACTIONS ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const actions = {
	// gestione keepalive
	keepAlive(context) {
		if (context.state.isLoggedIn) {
			Vue.axios.post('/auth/keepalive').then(response => {
				// console.debug('## KeepAlive ok', response)
				if (response.data.Ok) {
					context.commit('KEEPALIVE_OK', response.data.UsersStatus)
				} else {
					console.error('#### KeepAlive Session KO')
					actions.logout(context)
					// actions.messageError(context, 'Sessione scaduta!')
				}
			}).catch(error => {
				console.error('#### KeepAlive KO', error)
				context.commit('KEEPALIVE_FAIL')
				// consentiamo due solo fail, poi scatta il logout
				if (context.state.keepAliveFail > 2) {
					// location.reload()
					context.commit('NETWORK_ERROR', true)
					setTimeout(() => {
						context.commit('NETWORK_ERROR', false)
					}, 4000)
					actions.forcedLogout(context) // chiama il forced che differentemente dal normale non tenta neppure di avvertire il server
					// actions.messageError(context, 'Connessione persa!')
				}
			})
		}
	},
	// esegue il login contattando l'apposita api
	login(context, data) {
		return new Promise((resolve, reject) => {
			context.commit('INIT')
			Vue.axios.defaults.headers.common['Authorization'] = ''
			let endPoint = data.TxtGuid ? '/auth/ssologin' : '/auth/login'
			Vue.axios.post(endPoint, data).then(response => {
				// login ok, abbiamo il token -> impostato come default axios
				Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.Jwt
				context.commit('SET_VERSION', response.data)
				// carica utente e un po di decodifiche varie

				Promise.all([
					Vue.axios.get('/setup/currentvalues'),
					// Vue.axios.post('RisorseUmane/Persone/List'),
					// Vue.axios.post('RisorseUmane/Valutazioni/List'),
				]).then(function([responseSetup]) {
					// controllo versione con il server e reload se necessario
					// actions.testClientVersion(context, response.data.clientVersion)
					// salviamo i dati utente
					context.commit('SET_UTENTE', responseSetup.data.User)
					context.commit('SET_KEYVALUES', responseSetup.data.Keyvalues)
					context.commit('SET_SETUP', responseSetup.data.Setup)
					context.commit('SET_DECODIFICHE', responseSetup.data.Decodifiche)
					// struttura del menu
					context.commit('SET_MENU', responseSetup.data.UiMenu)					
					// carica i setup utente (debugmode, temi, tipologiacampi, ecc)
					actions.initUserSetup(context)
					// avvia il keepalive
					var keepAliveIntervalId = setInterval(() => {
						actions.keepAlive(context)
					}, 10000)
					// tutto a posto completiamo il login e gli passo l'oggetto interval del keepalive per il successivo reset in fase di logout
					context.commit('LOGIN', keepAliveIntervalId)
					// forza la route per il caricamento della pagina di benvenuto
					router.push({ name: 'home' })
					resolve()
				}).catch(error => {
					context.commit('INIT_FAILED')
					console.error('error in get/setup/currentValues\n', error)
					reject(error)
				})
			}).catch(error => {
				context.commit('INIT_FAILED')
				console.error('error in post/auth/login\n', error)
				reject(error)
			})
		})
	},
	// logout
	logout(context) {
		console.warn('logout!')
		actions.genericApiPost(context, {apiUrl: '/auth/logout'}).then(() => {
			context.commit('LOGOUT')
			router.replace({
				name: 'login'
			}).catch(error => {
				console.error('errore durante il logout !!!\n', error)
			})
		}).catch(error => {
			// se fallisce la chiamata alla api di logout è probabile che ci sia un problema di rete, forziamo quindi comunque il logout locale
			console.error('logout forzato !!!!\n', error)
			context.commit('LOGOUT')
			router.replace({
				name: 'login'
			}).catch(error => {
				console.error('errore durante il logout forzato !!!!!\n', error)
			})
		})
	},
	forcedLogout(context) {
		console.warn('forced logout!')
		context.commit('LOGOUT')
		router.replace({
			name: 'login'
		})
	},
	// -----------------------------------------------------------------
	// --------- CHIAMATE API GENERICHE --------------------------------
	// -----------------------------------------------------------------
	// GET generica (accetta data.apiUrl per l'indirizzo da chiamare ed espone il risultato come promise)
	genericApiGet(context, apiUrl) {
		return new Promise((resolve, reject) => {
			Vue.axios.get(apiUrl).then((response) => {
				console.debug('get ' + apiUrl, response)
				resolve(response.data)
			}).catch((error) => {
				var humanizedError = 'Errore generico durante la get'
				if (!!error.response && !!error.response.data) {
					humanizedError = error.response.data
				}
				console.error('error in get ' + apiUrl, humanizedError, error.response)
				reject(humanizedError)
			})
		})
	},
	// POST generica (accetta data.apiUrl per l'indirizzo da chiamare, data.value per i dati da postare e data.options per eventuali opzioni speciali da passare ad axios, espone il risultato come promise)
	genericApiPost(context, data) {
		return new Promise((resolve, reject) => {
			Vue.axios.post(data.apiUrl, data.value, data.options || {}).then((response) => {
				console.debug('post ' + data.apiUrl, 'options:', data.options, 'response:', response)
				resolve(response.data)
			}).catch((error) => {
				var humanizedError = 'Errore generico durante la post'
				if (!!error.response && !!error.response.data) {
					humanizedError = error.response.data
				}
				console.error('error in post ' + data.apiUrl, humanizedError, error.response)
				reject(humanizedError)
			})
		})
	},
	// FORM UPLOAD generica (come una normale post solo che aggiunge l'header 'multipart/form-data')
	// todo: rimuovere ed usare la normale genericApiPost con parametro options = {headers: { 'Content-Type': 'multipart/form-data'}}
	genericApiFormUpload(context, data) {
		return new Promise((resolve, reject) => {
			Vue.axios({method: 'POST', url: data.apiUrl, data: data.value, headers: { 'Content-Type': 'multipart/form-data'}}).then((response) => {
				console.debug('post form ' + data.apiUrl, response)
				resolve(response.data)
			}).catch((error) => {
				var humanizedError = 'Errore generico durante la post form'
				if (!!error.response && !!error.response.data) {
					humanizedError = error.response.data
				}
				console.error('error in post form ' + data.apiUrl, humanizedError, error.response)
				reject(humanizedError)
			})
		})
	},
	// .................................................................
	// ......... UTILITY ...............................................
	// .................................................................
	// restituisce un intero negativo crescente (nell'ambito della sessione vuex)
	// viene utilizzato per creare gli id temporanei dei record aggiuntivi da inviare a .net dove il negativo viene comuque considerato zero e aggioranto
	getNegativeIncrementId(context) {
		context.commit('INCREMENT_NEGATIVE_ID')
		return state.negativeIncrementalId
	},
	// commuta il tema corrente
	// NB di fatto si limita a salvare il dato ricevuto perché ad oggi non sono ancora riuscito a modificare le impostazioni di vuetify da vuex
	toggleTheme(context, darkMode) {
		// salva il tema
		var ukv = {
			UserId: context.state.user.UserName,
			Key: 'theme.dark',
			Value: darkMode
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// commuta la visualizza gli elementi di debug 
	toggleDebugElements(context) {
		context.commit('TOGGLE_DEBUG_ELEMENTS')
		var ukv = {
			UserId: context.state.user.UserName,
			Key: 'debugmode.on',
			Value: context.state.viewDebugElements
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// imposta aspetto grafico campi di edit
	setTipologiaCampiEdit(context, value) {
		context.commit('SET_TIPOLOGIA_CAMPI_EDIT', value)
		var ukv = {
			UserId: context.state.user.UserName,
			Key: 'tipologiaCampiEdit',
			Value: JSON.stringify(value)
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues l'aspetto dei campi di edit
	initTipologiaCampiEdit(context) {
		if(state.keyvalues['tipologiaCampiEdit']) {
			var value = JSON.parse(state.keyvalues['tipologiaCampiEdit'])
			if(value) context.commit('SET_TIPOLOGIA_CAMPI_EDIT', value)
		}
	},
	// commuta la visualizzazione compatta (dense)
	toggleCompactVisualization(context) {
		context.commit('TOGGLE_COMPACT_VISUALIZATION')
		var ukv = {
			UserId: context.state.user.UserName,
			Key: 'compactVisualization.on',
			Value: context.state.compactVisualization
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues la visualizzazione compatta (dense)
	initCompactVisualization(context) {
		if(state.keyvalues['compactVisualization.on']) {
			var loadedValue = state.keyvalues['compactVisualization.on'] === 'true'
			var currentValue = state.compactVisualization
			if(loadedValue !== currentValue) context.commit('TOGGLE_COMPACT_VISUALIZATION')
		}
	},
	// commuta la modalita' di apertura del cellulare tra telefono e whatsapp
	toggleCellularOpenMode(context) {
		context.commit('TOGGLE_CELLULAR_OPEN_MODE')
		var ukv = {
			UserId: context.state.user.UserName,
			Key: 'cellularOpenMode.whatsapp',
			Value: context.state.cellularOpenMode
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues la modalita' di apertura del cellulare tra telefono e whatsapp
	initCellularOpenMode(context) {
		if(state.keyvalues['cellularOpenMode.whatsapp']) {
			var loadedValue = state.keyvalues['cellularOpenMode.whatsapp'] === 'true'
			var currentValue = state.cellularOpenMode
			if(loadedValue !== currentValue) context.commit('TOGGLE_CELLULAR_OPEN_MODE')
		}
	},
	// commuta la modalita' di uscita dalla pagina persona
	togglePersoneExitAfterSave(context) {
		context.commit('TOGGLE_PERSONE_EXIT_AFTER_SAVE')
		var ukv = {
			UserId: context.state.user.UserName,
			Key: 'personeExitAfterSave',
			Value: context.state.personeExitAfterSave
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues la modalita' di uscita alla pagina persona
	initPersoneExitAfterSave(context) {
		if(state.keyvalues['personeExitAfterSave']) {
			var loadedValue = state.keyvalues['personeExitAfterSave'] === 'true'
			var currentValue = state.personeExitAfterSave
			if(loadedValue !== currentValue) context.commit('TOGGLE_PERSONE_EXIT_AFTER_SAVE')
		}
	},
	// inizializza i setup utente da keyvalues
	initUserSetup(context) {
		actions.initTipologiaCampiEdit(context)	
		actions.initCellularOpenMode(context)
		actions.initCompactVisualization(context)
		actions.initPersoneExitAfterSave(context) 
		// todo: debug mode (attualmente impostato nella master)
		// todo: tema (attualmente impostato nella master)
	},
	// .................................................................
	// ......... GESTIONE SETUP KEY VALUES USER ........................
	// .................................................................
	// carica keyvalue per l'utente corrente
	loadUserKeyvalues(context) {
		return actions.genericApiPost(context, {apiUrl: '/setup/keyvalue/current'})
	},
	// salva una keyvalue per l'utente corrente
	setUserKeyvalue(context, ukv) {
		return new Promise((resolve) => {
			actions.genericApiPost(context, {apiUrl: '/setup/keyvalue/current/set', value: ukv}).then((response) => {
				// se la chiave salvata è per l'utente corrente allora ricarico le sue chiavi
				if(ukv.UserId === state.user.UserName) {
					actions.loadUserKeyvalues(context).then((response) => {
						context.commit('SET_KEYVALUES', response)
						resolve(response)
					})
				} else {
					resolve(response)
				}
			})
		})
	},
	// .................................................................
	// ......... GESTIONE DOCUMENTI ........................
	// .................................................................
	openDocument(context, fileId) {
		return new Promise((resolve, reject) => {
			actions.genericApiPost(context, {apiUrl: '/file/get', value: {Id: fileId}}).then((result) => {
				// spacchetta il base64 dal contentType
				let dummy1 = result.FileData.split(',')
				let data = dummy1[1]
				let contentType = dummy1[0].replace('data:', '').replace(';base64', '')
				// converte da base64 a binario (todo: migliorare questo codice poco pulito)
				const bstr = atob(data)
				let n = bstr.length
				const u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				// crea il blob con il giusto content type 
				let blob = new Blob([u8arr], { type: contentType })
				// crea il link farlocco per aprire il doc
				let link = document.createElement('a')
				document.body.appendChild(link) // fix per firefox
				link.setAttribute('type', 'hidden') // fix per firefox
				link.href = window.URL.createObjectURL(blob)
				link.target = '_blank'
				link.download = result.Nome // imposta il nome del file
				link.click()
				resolve(result)
			}).catch(error => {
				console.error('openDocument error', error)
				reject(error)
			})
		})
	},	

}

// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
export default new Vuex.Store({
	strict: true,
	state: state,
	getters: getters,
	mutations: mutations,
	actions: actions
})
