'use strict'

import Vue from 'vue'
import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
	// baseURL: process.env.VUE_APP_WEBAPIROOT, // + 'api/ui', // il progetto skoda usa .core 5, quindi cartella wwwroot, quindi non è più necessario l'uso della sottocartella ui
	baseURL: process.env.VUE_APP_WEBAPIROOT, // + 'api/', 
	// timeout: 60 * 1000, // Timeout
	withCredentials: true, // Check cross-site Access-Control
	headers: { 'Content-Type': 'application/json' }
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		return config
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error)
	}
)

// Add a response interceptor
_axios.interceptors.response.use(
	function(response) {
		// Do something with response data
		return response
	},
	function(error) {
		// Do something with response error
		return Promise.reject(error)
	}
)

// eslint-disable-next-line no-unused-vars
Plugin.install = function(Vue, options) {
	Vue.axios = _axios
	window.axios = _axios
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios
			}
		},
		$axios: {
			get() {
				return _axios
			}
		},
	})
}

Vue.use(Plugin)

export default Plugin
