import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

Vue.use(VueRouter)

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/login',
			name: 'login',
			component: () => import(/* webpackPrefetch: true */ '@/views/Login.vue')
		},
		{
			path: '/sso/:txtGuid',
			name: 'sso',
			component: () => import(/* webpackPrefetch: true */ '@/views/Sso.vue')
		},
		{
			path: '/',
			name: 'master',
			component: () => import(/* webpackPrefetch: true */ '@/views/Master.vue'),
			meta: { requiresAuth: true },
			children: [
				{
					path: '/home',
					name: 'home',
					component: () => import(/* webpackPrefetch: true */ '@/views/Home.vue'),
					meta: { requiresAuth: true }
				},
				{
					path: '/card',
					name: 'card',
					component: () => import(/* webpackPrefetch: true */ '@/views/Cards/Cards.vue'),
					meta: { requiresAuth: true },
				},
				{
					path: '/contributi',
					name: 'contributi',
					component: () => import(/* webpackPrefetch: true */ '@/views/Contributi/Contributi.vue'),
					meta: { requiresAuth: true },
				},
				{
					path: '/report',
					name: 'report',
					component: () => import(/* webpackPrefetch: true */ '@/views/Report/Report.vue'),
					meta: { requiresAuth: true },
				},
				{
					path: '/promozioni',
					name: 'promozioni',
					component: () => import(/* webpackPrefetch: true */ '@/views/Promozioni/Promozioni.vue'),
					meta: { requiresAuth: true },
				},
				{
					path: '/servizi',
					name: 'servizi',
					component: () => import(/* webpackPrefetch: true */ '@/views/Servizi/Servizi.vue'),
					meta: { requiresAuth: true },
				},
				// -- ----- --------------------------------------------------------
				{
					path: '/logout',
					name: 'logout',
					component: () => import(/* webpackPrefetch: true */ '@/views/Logout.vue'),
				},
				{
					path: '/impostazioni/:tab',
					name: 'impostazioni',
					component: () => import(/* webpackPrefetch: true */ '@/views/Impostazioni.vue'),
					meta: { requiresAuth: true }
				},
				// -- admin --------------------------------------------------------
				{
					path: '/admin/cardclientitelai',
					name: 'admin.cardclientitelai',
					component: () => import(/* webpackPrefetch: true */ '@/views/Admin/CardClientiTelai/CardClientiTelai.vue'),
					meta: { requiresAuth: true}
				},
				{
					path: '/admin/modificapunti',
					name: 'admin.modificapunti',
					component: () => import(/* webpackPrefetch: true */ '@/views/Admin/ModificaPunti/ModificaPunti.vue'),
					meta: { requiresAuth: true}
				},
				{
					path: '/admin/gestionefiles',
					name: 'admin.gestionefiles',
					component: () => import(/* webpackPrefetch: true */ '@/views/Admin/GestioneFiles/GestioneFiles.vue'),
					meta: { requiresAuth: true}
				},
				{
					path: '/admin/users',
					name: 'admin.users',
					component: () => import(/* webpackPrefetch: true */ '@/views/Admin/Users.vue'),
					meta: { requiresAuth: true}
				},
				{
					path: '/admin/sitesetup',
					name: 'admin.sitesetup',
					component: () => import(/* webpackPrefetch: true */ '@/views/Admin/Sitesetup.vue'),
					meta: { requiresAuth: true}
				},
				{
					path: '/admin/log',
					name: 'admin.log',
					component: () => import(/* webpackPrefetch: true */ '@/views/Admin/Log.vue'),
					meta: { requiresAuth: true}
				},
			]
		},
		{
			path: '/error',
			component: () => import(/* webpackPrefetch: true */ '@/views/Error.vue')
		},
		{
			path: '*',
			component: () => import(/* webpackPrefetch: true */ '@/views/Error404.vue')
		},
	],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

// verifica metatag e autorizzazioni
router.beforeEach((to, from, next) => {
	let isLoggedIn = store.getters['isLoggedIn']
	/*
	non servono più perché abbiamo risolto con la lista delle route autorizzate
	let isAdmin = store.getters['currentUser'].IsAdmin
	let isSuperuser = store.getters['currentUser'].IsSuperuser
	let isDealer = store.getters['currentUser'].IsDealer
	let isOrganizzato = store.getters['currentUser'].IsOrganizzato
	*/
	let authorizedRoutes = store.getters['currentUser'].AuthorizedRoutes
	let isAuthorizedRoute = isLoggedIn && !!authorizedRoutes.find( routeName => { return routeName === to.name } ) 
	if(to.name=='impostazioni') {
		// casi particolari da autorizzare anche se non presenti nella lista
		isAuthorizedRoute = true
	}
	let requiresAuth = !!to.meta.requiresAuth
	// console.warn('routing to:' + to.name + ' requiresAuth:' + requiresAuth + ' isAuthorizedRoute:' + isAuthorizedRoute + ' isLoggedIn:' + isLoggedIn + ' .', to )
	if (!requiresAuth) {
		// route visibile a tutti senza login
		next()
	}
	else if (requiresAuth && !isLoggedIn) { 
		// route richiede autorizzazione e l'utente non è loggato, quindi porta al login
		console.warn('not loggedin: ', to.name)
		next({ name: 'login' })
	}
	else if (requiresAuth && isLoggedIn && !isAuthorizedRoute ) { 
		// la route richiede autorizzazione, l'utente è loggato ma non ne ha diritto perchè non compare tra le sue authorizedRoutes, quindi porta alla home
		if(to.name==='home') {
			console.warn('h 2 h !')
			next()
		}
		else {
			console.warn('unauthorized route: ', to.name)
			next({ name: 'home' })
		}
	}
	else next() // tutto ok
	
})

export default router
