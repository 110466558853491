import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import it from 'vuetify/es5/locale/it'
import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
	lang: {
		locales: { it },
		current: 'it',
	},
	theme: {
		dark: false,
		themes: {
			light: {
				primary: '#0E3A2F',
				secondary: '#A0A7A8',
				tertiary: '#CACECF',
				accent: '#ED4DF',
				success: '#2ba687', // fuori palette skoda ma altrimenti non si vedeva
				warning: '#F7B046',
				error:'#F15252',
				info: '#0961A1',
				tableheader: '#78FAAE',
				homebk: 'rgba(255,255,255,0.75)',
			  },
			dark: {
				primary: '#78FAAE',
				tableheader: '#0E3A2F',
				homebk: 'rgba(0,0,0,0.75)',
			},
		}
	},
	icons: {
		iconfont: 'mdiSvg'
	}
})
